import React from 'react'
import Layout from '../components/Layout'
import SEO from "../components/SEO";

const ScholarshipSuccess = () => (
    <Layout>
        <SEO
            title={"Scholarship Success" }
            description={""}
        />
        <section className="w-full px-4 md:px-6 py-6 container mx-auto markdown-styles">
            <h1 className="md:text-4xl font-semibold leading-10 text-3xl">Your Scholarship Application has been submitted</h1>
            <p className="leading-6">Thank you for your submission. Poppy Energy will review all submissions and the winner of the scholarship will be selected by November 1 and will be contacted via email.</p>

        </section>
    </Layout>
)

export default ScholarshipSuccess
